import { useState, useEffect, useRef, useCallback } from 'react';
import { isEmpty, isNumber, last } from 'lodash-es';
import toast from 'react-hot-toast';
import { useMountedState } from 'react-use';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator,
} from '@questpair/chat-ui-kit-react';
import { callAIConsultantApi, activateChat } from '../utils/api';
import {
  initialMessage,
  convertBackendMessageToChat,
  ChatMessage,
} from '../utils/chat-helpers.ts';
import { cssClassPrefix } from '../styles';
import { useModals } from '../providers';
import botAvatar from '../assets/img/chatbot.jpeg';
import { Spinner } from './Spinner.tsx';
import { MessageRating } from './messages-elements/MessageRating.tsx';
//import MagicBox from './MagixBox/MagicBox';
import TypewriterText from './texts/Typewriter.tsx';
import {
  MessageCircleIcon,
  ChevronDownIcon,
  RotateIcon,
  ArrowRightIcon,
  InfoIcon,
} from "./chat-elements/ChatIcons";
import WelcomeScreen from "./chat-elements/WelcomeScreen";
import InfoSection from "./chat-elements/InfoSection";
import {
  Wrapper,
  ChatTabButton,
  ChatContentContainer,
  ChatSection,
  ExpandedChatContainer,
  ChatTitle,
  AvatarWrapper,
  ChatControls,
  IconButton,
  SendButton,
  LoadingContainer,
  LoadingText,
  Notice,
  ChatHeader,
  BetaBadge,
} from "./chat-elements/ChatStyle";


//import { getEquipmentSuggestions } from './MagixBox/EquipmentSuggestions.ts';

const INITIAL_MODAL_SHOWN_KEY = 'chat_initial_modal_shown';
const ACTIVE_POLLING_INTERVAL = 3000; // 3 seconds when actively polling
const INACTIVE_POLLING_INTERVAL = 10000; // 10 seconds when actively polling
const BACKGROUND_POLLING_INTERVAL = 30000; // 30 seconds when tab not focused

{/*
const getRelevantItems = (message: string) => {
  // Only get equipment suggestions
  const equipmentSuggestions = getEquipmentSuggestions(message);
  return equipmentSuggestions;

};
*/}
// Export the function at the top level to make it more visible
const Chat = () => {
  const { dispatch } = useModals();
  const inputRef = useRef<HTMLDivElement>(null);
  const chatWrapperRef = useRef<HTMLDivElement>(null);
  const pollingIntervalRef = useRef<number | null>(null);
  const pollingTimeoutRef = useRef<number | null>(null);

  const [chatStarted, setChatStarted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [initialising, setInitialising] = useState(true);
  const [chatIsActive, setChatIsActive] = useState(false);
  const [captchIsSet, setCaptchIsSet] = useState(false);
  const [verifyingCaptcha, setVerifyingCaptcha] = useState(false);
  const [activePolling, setActivePolling] = useState(false);
  const [isWindowFocused, setIsWindowFocused] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [waitingForReply, setWaitingForReply] = useState(false);
  const [showInfoSection, setShowInfoSection] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [msgCount, setMsgCount] = useState(0);
  const [errorMsgCount, setErrorMsgCount] = useState(0);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [msgInput, setMsgInput] = useState<{
    innerHtml: string;
    innerText: string;
  }>({
    innerHtml: '',
    innerText: '',
  });

  //Magic Box states
  //const [showMagicBox, setShowMagicBox] = useState(false);
  //const [magicBoxItems, setMagicBoxItems] = useState<any[]>([]);
  //const [currentMessageForMagicBox, setCurrentMessageForMagicBox] = useState<string>('');


  const isMounted = useMountedState();

/*   const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (_key: any, value: object | null) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return "[Circular]";
        }
        seen.add(value);
      }
      return value;
    };
  };
 */

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowFocused(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    setIsWindowFocused(!document.hidden);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleTurnstileVerify = useCallback(async (token: string) => {
    if (!token) return;

    setVerifyingCaptcha(true);

    try {
      const activeResponse = await callAIConsultantApi('active', {
        data: {
          turnstile: token,
        },
      });

      if (!isMounted()) return;

      if (activeResponse.data.captcha) {
        setCaptchIsSet(true);
        toast.success("Verification successful");
      } else {
        toast.error("Verification failed. Please try again.");
      }
    } catch (error) {
      console.error('Error verifying captcha:', error);
      toast.error("Verification failed. Please try again.");
    } finally {
      setVerifyingCaptcha(false);
    }
  }, [isMounted]);

  const updateMessages = useCallback(
    async (all: 0 | 1 = 0) => {
      const answersResponse = await callAIConsultantApi('answers', {
        data: { all },
      });

      if (!isMounted()) return;

      if (
        answersResponse.data.status === 'ok' &&
        !isEmpty(answersResponse.data.answers)
      ) {
        setMsgCount(answersResponse.data.count);

        setMessages((prevMessages) => {
          const newMessages = answersResponse.data.answers.map(
            convertBackendMessageToChat
          );


          // Update magic box items based on the last incoming message
          /* const lastIncomingMsg = [...newMessages].reverse().find(msg => msg.direction === 'incoming');
          if (lastIncomingMsg && lastIncomingMsg.payload) {
            const messageText = typeof lastIncomingMsg.payload === 'string'
              ? lastIncomingMsg.payload
              : JSON.stringify(lastIncomingMsg.payload, getCircularReplacer());

            setCurrentMessageForMagicBox(messageText);
            const items = getRelevantItems(messageText);
            if (items.length > 0) {
              setMagicBoxItems(items);
              // Optionally set magic box visibility based on your requirements
              setShowMagicBox(false);
            }
          } */
          usePolling();
          setChatStarted(true);

          if (all) return [initialMessage, ...newMessages];
          return [...prevMessages, ...newMessages];
        });
      } else if (all) {
        setMessages([initialMessage]);
      }
    },
    [isMounted, msgCount]
  );

  // Open initial modal on component mount
  useEffect(() => {
    // Check if the modal has been shown before
    // Temporary we skip this popup. To prevent an overload of bots.
    const initialModalShown = true; // localStorage.getItem(INITIAL_MODAL_SHOWN_KEY);

    if (!initialModalShown) {
      dispatch([
        'setModalContent',
        'initial',
        {
          activateChat() {
            setChatStarted(false);
            setExpanded(true);
            inputRef.current?.focus();

            // Set flag in localStorage so it won't show again
            localStorage.setItem(INITIAL_MODAL_SHOWN_KEY, 'true');
          },
        },
      ]);

      // Set flag in localStorage so it won't show again even if they don't click the activate button
      localStorage.setItem(INITIAL_MODAL_SHOWN_KEY, 'true');
    }
  }, [dispatch]);

  // Initialising chat effect
  useEffect(() => {
    let mounted = true;
    requestAnimationFrame(async () => {
      if (!chatStarted){
        try {
          if (!mounted) return;
          const activeResponse = await activateChat();

          if (!mounted) return;

          if (!activeResponse.data.active) return;
          if (activeResponse.data.captcha)
            setCaptchIsSet(activeResponse.data.captcha);

          await updateMessages(1);

          if (!mounted) return;

          setChatIsActive(true);
        } catch (e) {
          console.error('Error on initialising chat', e);
        } finally {
          setInitialising(false);
        }
    }
    });
    return () => {
      mounted = false;
    };
  }, [updateMessages, chatStarted]);

  // Update messages from backend hook
  useEffect(() => {
    if (!chatIsActive || !captchIsSet || !expanded) return;

    // Clear any existing interval
    if (pollingIntervalRef.current !== null) {
      clearInterval(pollingIntervalRef.current);
    }

    // Function to fetch messages
    const fetchMessages = async () => {
      try {
        const answersResponse = await callAIConsultantApi('answers', {
          data: { all: 0 },
        });

        if (
          answersResponse.data.status === 'ok' &&
          !isEmpty(answersResponse.data.answers)
        ) {
          setMsgCount(answersResponse.data.count);
          setErrorMsgCount(0);
          setMessages((prevMessages) => [
            ...prevMessages,
            ...answersResponse.data.answers.map(convertBackendMessageToChat),
          ]);
        } else {
          if (answersResponse.data.status === 'ok' && answersResponse.data.count != msgCount) {
            await updateMessages(1).catch(() => {
              // Shouldn't be thrown
            });
          }
          if (chatStarted && answersResponse.data.status === 'error' && answersResponse.data.message === 'Chat not found') {
            setErrorMsgCount(errorMsgCount + 1);
            if (errorMsgCount > 1) {
              setErrorMsgCount(0);
              await onRestartClick(false)();
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };
    // fetchMessages();

    // Set up polling with dynamic interval based on focus state and activity
    const pollingInterval = activePolling
      ? ACTIVE_POLLING_INTERVAL // Active polling (3s)
      : isWindowFocused
        ? INACTIVE_POLLING_INTERVAL // Regular polling when window is focused (10s)
        : BACKGROUND_POLLING_INTERVAL; // Slower polling when window is not focused (30s)

    pollingIntervalRef.current = window.setInterval(fetchMessages, pollingInterval);
    fetchMessages();

    // Clean up on unmount or when dependencies change
    return () => {
      if (pollingIntervalRef.current !== null) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [chatIsActive, captchIsSet, activePolling, msgCount, errorMsgCount, isWindowFocused, expanded]);

  // Polling update
// Polling update
  const usePolling = (delay = 5 * 60 * 1000) => {
    // Clear any existing timeout first
    if (pollingTimeoutRef.current !== null) {
      clearTimeout(pollingTimeoutRef.current);
    }

    setActivePolling(true);
    pollingTimeoutRef.current = window.setTimeout(() => {
      setActivePolling(false);
      pollingTimeoutRef.current = null;
    }, delay);

    return () => {
      if (pollingTimeoutRef.current !== null) {
        clearTimeout(pollingTimeoutRef.current);
        pollingTimeoutRef.current = null;
      }
    };
  };

  useEffect(() => {
    if (sendingMessage) {
      const cleanupPolling = usePolling();
      return () => {
        cleanupPolling();
      };
    }
  }, [sendingMessage]);


  // Managing disabling waitingForReply
  useEffect(() => {
    if (!waitingForReply) return;
    if (last(messages)?.direction === 'incoming') {
      setWaitingForReply(false);
    }
  }, [waitingForReply, messages]);

  // Scroll to bottom when chat is expanded
  useEffect(() => {
    if (expanded && chatWrapperRef.current) {
      const messageList = chatWrapperRef.current.querySelector(`.${cssClassPrefix}-message-list__scroll-wrapper`);
      if (messageList) {
        setTimeout(() => {
          messageList.scrollTop = messageList.scrollHeight;
        }, 100)
      }
    }
  }, [expanded, messages]);

  const handleTypingStart = useCallback(() => {
    setIsTyping(true);

  }, []);

  const handleTypingComplete = useCallback(() => {
    setIsTyping(false);

  }, []);

  const onExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };
  useEffect(() => {
    const toTopElement = document.getElementById("totop");
    if (expanded) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);

      document.body.style.overflow = 'hidden';
      if(toTopElement)
        toTopElement.style.visibility = "hidden";
    } else {
      document.body.style.overflow = '';
      if(toTopElement)
        toTopElement.style.visibility = "visible";
    }
  }, [expanded]);

  const startNewChat = () => {

    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  };

  const onRestartClick = (send: boolean = true) => async () => {
    try {
      setChatIsActive(false);

      if(send) {
        await callAIConsultantApi('restart', {});
      }
    } catch (e) {
      console.error(e);
    } finally {
      setMessages([initialMessage]);
      setChatIsActive(true);
      setChatStarted(false);
      setIsTyping(false);
      //setShowMagicBox(false);
      //setMagicBoxItems([]);
    }
  };

  const toggleInfoSection = () => {
    setShowInfoSection(!showInfoSection);
    // Close magic box when info section is opened
    /* if (!showInfoSection) {
      setShowMagicBox(false);
    } */
  };

  /* const toggleMagicBox = () => {
    // Close info section when magic box is opened
    if (!showMagicBox) {
      setShowInfoSection(false);

      // Check if we have current items or need to generate new ones
      if (magicBoxItems.length === 0 && currentMessageForMagicBox) {
        const items = getRelevantItems(currentMessageForMagicBox);
        if (items.length > 0) {
          setMagicBoxItems(items);
          setShowMagicBox(true);
        } else {
          toast.error('No relevant information available for this message');
        }
      } else if (magicBoxItems.length > 0) {
        setShowMagicBox(true);
      } else {
        toast.error('No relevant information available');
      }
    } else {
      setShowMagicBox(false);
    }
  };
 */

  const handleSend = async (question: string) => {
    if (isTyping) {
      toast.error("Please wait until the assistant finishes responding");
      return;
    }

    try {
      setSendingMessage(true);


      const questionResponse = await callAIConsultantApi('question', {
        data: {
          question,
          restart: 0,
        },
      });

      if (questionResponse.data.status === 'error') {
        setIsTyping(false);
        throw new Error('questionResponse.data.status is error');

      }

      if (!isMounted()) return;

      await updateMessages(0).catch(() => {
        // Shouldn't be thrown, since we care about sending only here
      });
      setMsgInput({
        innerHtml: '',
        innerText: '',
      });
      setWaitingForReply(true);
      setIsTyping(true);
    } catch (e) {
      console.error(e);
      toast.error('Could not send message. Please try again');
    } finally {
      setSendingMessage(false);
      inputRef.current?.focus();
    }
  };

  const removeMessage = (id: number) => {
    setMessages((prevMessages) => {
      const messageToRemove = prevMessages.find(
        ({ id: messageId }) => messageId === id
      );
      if (!messageToRemove) return prevMessages;
      messageToRemove['hidden'] = true;
      return [...prevMessages];
    });
  };

  // Claude.ai style welcome screen
  if (!chatStarted && expanded) {
    return (
      <WelcomeScreen
        inputRef={inputRef}
        onExpandClick={onExpandClick}
        handleTurnstileVerify={handleTurnstileVerify}
        handleSend={handleSend}
        startNewChat={startNewChat}
        captchIsSet={captchIsSet}
        verifyingCaptcha={verifyingCaptcha}
        chatIsActive={chatIsActive}
        sendingMessage={sendingMessage}
        msgInput={msgInput}
        setMsgInput={setMsgInput}
      />)
  }

  return (
    <Wrapper $expanded={expanded} ref={chatWrapperRef}>
      {!expanded ? (
        <ChatTabButton onClick={onExpandClick} aria-label="Open AI Consultant">
          <MessageCircleIcon />
          <span>AI Consultant</span>
          <BetaBadge>Beta</BetaBadge>
        </ChatTabButton>
      ) : (
        <ChatContentContainer>
          <ChatSection>
            <ChatHeader>
              <ChatTitle onClick={toggleInfoSection}>
                <AvatarWrapper style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '32px',
                  width: '32px',
                }}>
                  <Avatar key="avatar" src={botAvatar} name="QuestPair AI" style={{
                    marginTop: '25px',
                    padding: '4px',
                  }} />
                </AvatarWrapper>
                <span>QuestPair Research Assistant (Beta)</span>

              </ChatTitle>
              <ChatControls>

                <IconButton
                  onClick={toggleInfoSection}
                  aria-label="Chat Information"
                  title="Chat Information"
                >
                  <InfoIcon />
                </IconButton>
                <IconButton
                  onClick={onRestartClick(true)}
                  disabled={(!chatIsActive || !captchIsSet)}
                  aria-label="Restart conversation"
                  title="Restart conversation"
                >
                  <RotateIcon />
                </IconButton>
                <IconButton
                  onClick={onExpandClick}
                  aria-label="Close chat"
                  title="Close chat"
                >
                  <ChevronDownIcon />
                </IconButton>

              </ChatControls>
            </ChatHeader>
            {showInfoSection && <InfoSection toggleInfoSection={toggleInfoSection}></InfoSection>}

            <ExpandedChatContainer>
              {initialising ? (
                <LoadingContainer>
                  <Spinner />
                  <LoadingText>Initializing your personal research assistant...</LoadingText>
                </LoadingContainer>
              ) : (
                <MainContainer>
                  <ChatContainer>
                    <MessageList
                      typingIndicator={
                        waitingForReply ? (
                          <TypingIndicator content="Assistant is thinking..." />
                        ) : null
                      }
                    >
                      {messages.map((m, i) => {
                        const msgChildren = (() => {
                          if (m.direction !== 'incoming') return null;

                          const children: JSX.Element[] = [];

                          if (
                            !(
                              isNumber(m.id) &&
                              (m.type === 'text' || m.productsSuggestion)
                            )
                          ) {
                            return children;
                          }

                          return [
                            ...children,
                            <Message.Footer key="footer">
                              {/* <IconMagicButton
                                onClick={toggleMagicBox}
                                aria-label="Toggle Magic Box"

                                title="Show relevant information"
                                disabled={magicBoxItems.length === 0}

                              >
                                <MagicBoxIcon />
                              </IconMagicButton> */}
                              <MessageRating
                                messageId={m.id}
                                removeMessage={
                                  m.productsSuggestion
                                    ? () => removeMessage(m.id!)
                                    : undefined
                                }
                              />

                            </Message.Footer>,
                          ];
                        })();

                        // Modified message content rendering
                        const messageContent = m.direction === 'incoming' && m.type === 'text'
                          ? (
                            <TypewriterText
                              text={m.payload?.toString() || ''}
                              sentTime={m.sentTime}
                              speed={20}
                              messageId={m.id} // Pass the message ID to track which messages have been typed
                              onTypingStart={handleTypingStart}
                              onComplete={handleTypingComplete}
                            />
                          )
                          : m.payload;


                        if (m.direction !== 'incoming') {
                          m.payload = m.payload?.toString()
                            .replace(/<br>/g, "\n")
                            .replace(/<[^>]*>?/gm, '')
                            .replace(/&nbsp;/g, " ");
                        }

                        return (
                          <Message
                            key={i}
                            model={{
                              ...m,
                              payload: messageContent
                            }}
                            avatarPosition="cl"
                            {...(m.hidden
                              ? { className: `${cssClassPrefix}-message_hidden` }
                              : {})}
                          >
                            {msgChildren}
                          </Message>
                        );
                      })}
                    </MessageList>

                    {/* Message Input Container */}
                    <div
                      // @ts-expect-error library's prop not update
                      as={MessageInput}
                      style={{
                        marginTop: 'auto',

                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: "20px 20px 20px 20px",
                        backgroundColor: '#fff',
                        paddingRight: 17,
                        boxShadow: "0px 0px 4px rgb(0,0,0,0.4)",
                        padding: "6px 16px",
                        alignItems: "center",
                        margin: "0px 5px",
                        background: "#E4E9E9",
                        zIndex: 9999999,
                        marginBottom: "10px",

                      }}
                    >
                      <MessageInput
                        ref={inputRef}
                        style={{
                          flexGrow: 1,
                          borderTop: 0,
                          flexShrink: 'initial',
                          borderRadius: "24px",
                          background: "#E4E9E9",
                          padding: "4px 8px",
                          marginRight: "12px",
                        }}
                        plaintextOnly={navigator.userAgent.match(/firefox|fxios/i) ? false : true}
                        disabled={!chatIsActive || !captchIsSet || sendingMessage}
                        sendOnReturnDisabled={false}
                        sendButton={false}
                        attachButton={false}
                        placeholder="Type message here"
                        value={msgInput.innerHtml}
                        onChange={(innerHtml, _, innerText) => {
                          setMsgInput({
                            innerHtml,
                            innerText,
                          });
                        }}
                        onSend={handleSend}
                      />
                      <SendButton
                        loading={sendingMessage.toString()}
                        disabled={msgInput.innerHtml.length === 0}
                        onClick={() => handleSend(msgInput.innerText)}
                      >
                        <ArrowRightIcon />
                      </SendButton>
                    </div>
                  </ChatContainer>
                </MainContainer>
              )}
              <Notice>Beta version - QuestPair Research Assistant can make mistakes. </Notice>
            </ExpandedChatContainer>

          </ChatSection>

          {/* {showMagicBox && magicBoxItems.length > 0 && (
            <MagicBoxSideContainer>
              <MagicBox
                items={magicBoxItems}
                isVisible={showMagicBox}
                onClose={() => setShowMagicBox(false)}
              />
            </MagicBoxSideContainer>
          )} */}
        </ChatContentContainer>
      )
      }
    </Wrapper >
  );
}

// Also export as a named export to maintain backward compatibility
export { Chat };
